<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             @click="backAnswer"
             src="@/assets/interesing/退出@2x.png"
             alt="">
        <span> 美文记忆</span>
      </div>
      <div class="topRight">
        <img src="@/assets/interesing/Left@2x (1).png"
             alt=""
             @click="back()">
        <img src="@/assets/interesing/Right@2x.png"
             alt=""
             @click="next()">
        <span @click="resfresh()">刷新</span>
        <img src="@/assets/interesing/发帖@2x.png"
             alt=""
             @click="showInsDialog">
        <img src="@/assets/interesing/反馈@2x.png"
             @click="feed"
             alt="">
      </div>
    </div>
    <div class="topic"
         v-for="(item) in topicListData"
         :key="item.answer_id"
         v-show="item.answer_id == idx">
      <div class="tips">题目：{{item.answer_id}}</div>
      <div class="score">{{item.question_type_name}}：{{item.question_score}}分，{{item.source_name}}</div>
      <div class="answer"
           v-html="item.question_content"></div>
      <div class="check"></div>
      <div class="reply">
        <div class="annv">
          <div class="parse"
               @click="back()">
            上一题
          </div>
          <div class="training"
               @click="drawer = true">开始默写</div>
        </div>
      </div>
      <el-drawer :visible.sync="drawer"
                 :direction="'btt'"
                 size="80%">
        <div>
          <div class="answer"
               style="padding: 40rem 40rem 32rem 40rem;"
               v-html="item.question_content"></div>
          <div class="answer"
               style="display:flex; flex-direction:row;">
            <div class="answer"
                 style="padding: 40rem 40rem 32rem 40rem;width: 50%;">
              <h3>我的答案：</h3>
              <p>
                <span v-for="(ans, index) in answers"
                      :key="index">
                  <div style="line-height:80rem;">

                    <span style=" 
                       
    padding: 6rem;
    font-size: 20rem;
    border: 2px solid rgb(51, 51, 51);
    background-color: transparent;
    border-radius: 50%;
    font-weight: 800;">{{index + 1}}</span>

                    <input type="text"
                           :class="{'wrong-answer': errorChoice[index] && answers[index] !== errorChoice[index] }"
                           style="border-left:0px;border-top:0px;border-right:0px;border-bottom-color:black;width:90%"
                           v-model.trim="answers[index]">
                  </div>
                </span>
              </p>
            </div>
            <div style="padding: 19rem 40rem 32rem; width: 50%;line-height: 90rem;">
              <h3 v-show="showAnswer">正确答案：</h3>
              <div class="answer"
                   v-html="item.question_answer"
                   v-show="showAnswer">
              </div>
            </div>
          </div>
          <div class="annv">
            <div class="parse"
                 @click="closeDialog">
              再看一次
            </div>
            <div class="training"
                 @click="checkAnswers">提交答案</div>
          </div>
        </div>
      </el-drawer>
    </div>
    <Form ref="FormRef" />
    <FeedBack ref="FeedBackRef" />
  </div>
</template>
<script>
import { getArticle, subArticle } from '@/api/Aaswer.js'
import Form from '@/views/interesting/components/form.vue'
import FeedBack from '@/views/interesting/components/feedback.vue'

export default {
  data () {
    return {
      answers: new Array(6),
      topicListData: [],
      idx: 1,
      question_content: '',
      rightNum: 0,
      errorNum: 0,
      group_id: '',
      subjectId: '',
      isActive: '',
      indexFlag: '',
      drawer: false,
      ans: '',
      showAnswer: false,
      falseFlag: 0,
      trueFlag: 0,
      errorChoice: []

    }
  },
  components: {
    Form,
    FeedBack
  },
  mounted () {
    setTimeout(() => {
      var doo = this.escapeHtml(this.topicListData[0].question_answer)
      var cpdoo = doo.split(" ")
      const squaredNumbers = cpdoo.filter(num => num != '').filter(num => num != '.');
      console.log('squaredNumbers', squaredNumbers)
      console.log('squaredNumbers.length', squaredNumbers.length)
      this.answers.length = squaredNumbers.length


    }, 1000)
  },
  async created () {
    let subjectId = Number(this.$route.query.subject_id)
    let topicList = await getArticle()
    this.topicListData = JSON.parse(JSON.stringify(topicList.data))
    this.group_id = topicList.data[0].group_id
    this.subjectId = subjectId
  },
  methods: {
    closeDialog () {
      this.drawer = false
      this.answers = []
      this.error = []
    },
    checkAnswers: function () {
      this.showAnswer = true
      this.falseFlag = 0
      this.trueFlag = 0
      this.errorChoice = []
      for (var i = 0; i < this.answers.length; i++) {
        var doo = this.escapeHtml(this.topicListData[0].question_answer, i)
        var cpdoo = doo.split(" ")
        const squaredNumbers = cpdoo.filter(num => num != '').filter(num => num != '.');

        if (this.answers[i] && (squaredNumbers[i] == this.answers[i])) {
          this.trueFlag = this.trueFlag + 1
        } else {
          this.falseFlag = this.falseFlag + 1


        }
        // console.log('this.falseFlag', this.falseFlag)
        // console.log('iiii', i)
        // console.log('squaredNumbers[i]', squaredNumbers[i])
        // console.log('this.answers[i]', this.answers[i])
        // console.log('this.errorChoice[i]', this.errorChoice[i])
        if ((this.falseFlag - 1 == i) && this.answers[i]) {
          this.errorChoice[i] = squaredNumbers[i]
        }

        if (i + 1 == this.answers.length && this.answers[i]) {

          if (this.falseFlag > 0) {
            this.$message.error('回答错误')
          } else {
            this.$message.success('回答正确')
          }

        }


        if (this.answers[i] == undefined) {
          this.$message.error('回答错误')
          return;
        }
      }

    },
    // html处理方法
    escapeHtml (str, index) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'amp': '&',
        'quot': '"',
        'dh': '，'
      };
      let htmlTag = str.replace(/&(lt|gt|amp|quot);/ig, function (all, t) {
        return arrEntities[t];
      });

      return htmlTag.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, " ").replace(/\（.*?\）/g, " ").replace(/，/g, ' ').replace(/\(.*?\)/g, ' ').replace(/①/g, ' ').replace(/②/g, ' ').replace(/③/g, ' ').replace(/④/g, ' ').replace(/⑤/g, ' ').replace(/⑥/g, ' ').replace(/⑦/g, ' ').replace(/⑧/g, ' ').replace(/⑨/g, ' ').replace(/⑨/g, ' ').replace(/⑩/g, ' ').replace(/⑩/g, ' ').replace(/⑪/g, ' ').replace(/⑫/g, ' ').replace(/⑬/g, ' ').replace(/⑭/g, ' ').replace(/⑮/g, ' ').replace(/⑯/g, ' ').replace(/⑰/g, ' ').replace(/⑱/g, ' ').replace(/⑲/g, ' ').replace(/⑳/g, ' ').replace(/㉑/g, ' ').replace(/㉒/g, ' ').replace(/㉓/g, ' ').replace(/㉔/g, ' ').replace(/㉕/g, ' ').replace(/；/g, ' ').replace(/示例一：/g, ' ').replace(/示例二：/g, ' ').replace(/示例三：/g, ' ').replace(/示例：/g, ' ')
    },
    feed () {
      let form = {
        category: 0,
        comment: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FeedBackRef.form = form
      this.$refs.FeedBackRef.dialogVisible = true
    },
    showInsDialog () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
    },
    resfresh () {
      this.$router.go(0)
    },
    back () {
      if (this.idx == 1) {
        this.$message.warning('已经是第一题')
      } else {
        this.idx--
      }
      var doo = this.escapeHtml(this.topicListData[this.idx - 1].question_answer)
        var cpdoo = doo.split(" ")
        const squaredNumbers = cpdoo.filter(num => num != '').filter(num => num != '.');
        
        this.answers = []
        this.answers.length = squaredNumbers.length
        this.showAnswer = false

    },
    next () {
      if (this.idx < this.topicListData.length) {
        this.isActive = ''
        this.idx++
        var doo = this.escapeHtml(this.topicListData[this.idx - 1].question_answer)
        var cpdoo = doo.split(" ")
        const squaredNumbers = cpdoo.filter(num => num != '').filter(num => num != '.');
        
        this.answers = []
        this.answers.length = squaredNumbers.length
        this.showAnswer = false

      }
    },
    backAnswer () {
      this.$router.push({
        path: '/home',
      })
    },
    optionClick (option, idx) {
      const item = this.topicListData[idx - 1];
      this.indexFlag = this.topicListData[idx - 1]
      this.isActive = option
      item.choiceResult = option;
      if (item.question_answer == option) {
        item.isTrue = true;
        this.rightNum++;
      } else {
        item.isTrue = false;
        this.errorNum++;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__wrapper{
  z-index: 9999 !important;
}
.content {
  
  cursor: pointer;
  
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;

      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      .data {
        width: 32rem;
        height: 32rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
    .topRight {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 48rem;
        height: 48rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
        margin-left: 24rem;
      }
    }
  }
  .topic {
    padding: 40rem 40rem 32rem 40rem;
    box-sizing: border-box;
    .tips {
      font-size: 28rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42rem;
      margin-bottom: 8rem;
    }
    .score {
      font-size: 24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36rem;
      margin-bottom: 32rem;
    }
    .answer {
      font-size: 32rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 32rem;
      .wrong-answer {
        color: red;
      }
    }
    .check {
      height: 240rem;
      margin-bottom: 32rem;
    }
    .reply {
      display: flex;
      height: 152rem;
      justify-content: space-between;
      color: #333333;
      padding: 8rem;
      box-sizing: border-box;
      .reprybg {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .option {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid #f5f5f5;
          align-items: center;
          font-size: 32rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .annv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 28px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2196f3;
      .parse {
        width: 880rem;
        height: 80rem;
        border-radius: 100rem;
        border: 1px solid #2196f3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .training {
        width: 880rem;
        height: 80rem;
        border-radius: 100rem;
        background: #2196f3;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
      }
    }
  }
}
</style>